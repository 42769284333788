// extracted by mini-css-extract-plugin
export var blockColour = "customers-module--blockColour---+N92";
export var description = "customers-module--description--tXbDC";
export var featureImage = "customers-module--featureImage---LPUZ";
export var firmName = "customers-module--firmName--0jDH3";
export var isStyleH1 = "customers-module--is-style-h1--YoXbe";
export var isStyleH2 = "customers-module--is-style-h2--Hsw7h";
export var isStyleH3 = "customers-module--is-style-h3--mZGW9";
export var isStyleH4 = "customers-module--is-style-h4--y44EY";
export var isStyleH5 = "customers-module--is-style-h5--ORV6U";
export var isStyleH6 = "customers-module--is-style-h6--5aTaQ";
export var logo = "customers-module--logo--2eqE7";
export var mainWrapper = "customers-module--mainWrapper--JzDXp";
export var storiesBlockWrapper = "customers-module--storiesBlockWrapper--DzsNt";
export var storyBlock = "customers-module--storyBlock--4wDyZ";
export var video = "customers-module--video--WgjNU";
export var videoCaption = "customers-module--videoCaption--AaGcG";
export var videoHeroTitle = "customers-module--videoHeroTitle--XZFjE";
export var videoWrapper = "customers-module--videoWrapper--k3cX7";
export var viewMoreButton = "customers-module--viewMoreButton--F4tMp";