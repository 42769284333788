import React, { CSSProperties } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import { Maybe, SanityImage } from "@graphql-types"

const StyledImage = styled(GatsbyImage)<{
  width: string | undefined
  filter: string | undefined
  maxHeight: string | undefined
  height: string | undefined
  margin: string | undefined
}>`
  width: ${props => props.width ?? "auto"};
  height: ${props => props.height ?? "auto"};
  ${({ filter }) => filter && `filter: ${filter};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`

const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`

interface Props {
  data: Maybe<SanityImage>
  minHeight?: string
  isBackground?: boolean
  width?: string
  height?: string
  onClick?: () => void
  objectFit?: CSSProperties["objectFit"]
  margin?: string
  filter?: string
  maxHeight?: string
}

const ImageObject = (props: Props) => {
  const { objectFit } = props
  const imageData = props.data?.asset?.gatsbyImageData

  if (props.isBackground && imageData) {
    return (
      <BackgroundImageWrapper>
        <StyledImage
          image={imageData}
          style={{ margin: props.margin, height: "100%" }}
          width={props.width}
          filter={props.filter}
          itemProp="image"
          alt={props.data?.altText ?? "bg-image"}
          onClick={props.onClick}
          objectFit={objectFit ?? "cover"}
          maxHeight={props.maxHeight}
          height={props.height}
        />
      </BackgroundImageWrapper>
    )
  }

  if (imageData) {
    return (
      <StyledImage
        image={imageData}
        style={{ margin: props.margin }}
        width={props.width}
        height={props.height}
        filter={props.filter}
        itemProp="image"
        alt={props.data?.altText ?? "gatsby-image"}
        onClick={props.onClick}
        objectFit={objectFit ?? "contain"}
        maxHeight={props.maxHeight}
      />
    )
  }

  return null
}

const Image = (props: Props) => {
  if (!props.data) return null

  const { link } = props.data
  const newWindow = link && link.indexOf("http") > -1

  if (link) {
    if (newWindow) {
      return (
        <a href={link}>
          <ImageObject {...props} />
        </a>
      )
    }

    return (
      <GatsbyLink to={link}>
        <ImageObject {...props} />
      </GatsbyLink>
    )
  }

  return <ImageObject {...props} />
}

export default Image
