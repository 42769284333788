// Core
import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/customers/seo"
import HeroHeader from "../components/customers/heroHeader"
import CustomerStoriesBlock from "../components/customers/customerStories"

import "../global-styless/global.scss"

const ProductPage = () => {
  return (
    <Layout>
      <SEO />
      <HeroHeader />
      <CustomerStoriesBlock />
    </Layout>
  )
}

export default ProductPage
