import React, { Dispatch, ReactNode, SetStateAction } from "react"
import Modal from "react-modal"
import styled from "styled-components"

import { assets, colorsRGB, TABLET_BREAKPOINT } from "@util/constants"
import { Container } from "@util/standard"

interface Props {
  children: ReactNode
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  width?: string
  height?: string
  hideCloseIcon?: boolean
  customStyle?: {
    content: any
    overlay: any
  }
}

const CloseIcon = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  top: -30px;
  right: 25px;
  cursor: pointer;
`

const StyledContainer = styled(Container)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

function ModalBox(props: Props) {
  const {
    children,
    setVisible,
    visible,
    width,
    height,
    customStyle,
    hideCloseIcon,
  } = props

  const modalBaseStyle = {
    content: {
      top: "50%",
      left: "50%",
      width: width ?? "70%",
      height: height ?? "80%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "20px",
      overflow: "visible",
      background: "transparent",
    },
    overlay: {
      backgroundColor: `${colorsRGB.black(0.6)}`,
      zIndex: 100,
    },
  }

  return (
    <>
      <Modal
        style={customStyle ? customStyle : modalBaseStyle}
        isOpen={visible}
        ariaHideApp={false}
        onRequestClose={() => setVisible(false)}
        shouldCloseOnEsc
      >
        {!hideCloseIcon && (
          <CloseIcon src={assets.closeIcon} onClick={() => setVisible(false)} />
        )}
        <StyledContainer>{children}</StyledContainer>
      </Modal>
    </>
  )
}

export default ModalBox
