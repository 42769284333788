import React, { useState, useRef, useContext, useEffect } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { LocationsContext } from "../../contexts/locationsContext"
import * as styles from "./customers.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import SanityMuxPlayer from "sanity-mux-player"
import { FaPlay } from "react-icons/fa"
import "./videoPlayer.sass"
import Video from "../shared/video"

const CustomerStoriesBlock = () => {
  const { sanityCustomerPage: data } = useStaticQuery(graphql`
    query successStories {
      sanityCustomerPage {
        customerStories {
          loactions {
            locations {
              countryCode
            }
          }
          firmName
          lawFirmLogo {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          colorlist
          storyDescription
          heroImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          customerStoryVideo {
            asset {
              assetId
              _type
              filename
              playbackId
              status
              thumbTime
            }
          }
          video {
            url
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(data.customerStories)

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(data.customerStories)
    } else {
      const filteredData = data.customerStories.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  //   const assetDocument = {}

  const returnVideoComponent = story => {
    if (story.video) {
      return (
        <Video
          url={story.video.url}
          thumbnail={story.video.image}
          width="100%"
          height="100%"
          thumbnailHeight="280px"
        />
      )
    }
    if (story.heroImage) {
      return (
        <GatsbyImage
          image={story.heroImage.asset.gatsbyImageData}
          key={story._key}
          className={styles.featureImage}
          alt={story.heroImage.originalFilename}
        />
      )
    }
  }

  return (
    <div className={styles.mainWrapper}>
      <h2 className={styles.videoHeroTitle}>Customer Stories</h2>

      <div className={styles.storiesBlockWrapper}>
        {integrations.length > 0 ? (
          integrations.map(story => (
            <div className={styles.storyBlock}>
              {returnVideoComponent(story)}

              {story.lawFirmLogo && (
                <GatsbyImage
                  image={story.lawFirmLogo.asset.gatsbyImageData}
                  key={story._key}
                  className={styles.logo}
                  alt={story.lawFirmLogo.originalFilename}
                />
              )}

              <p className={styles.description}>{story.storyDescription}</p>
              <p className={styles.firmName}>{story.firmName}</p>
              <div
                className={styles.blockColour}
                style={{ background: story.colorlist }}
              >
                {" "}
              </div>
            </div>
          ))
        ) : (
          <p className="empty-notice">
            Sorry, no Customers were found in region:{" "}
            {currentLocation.countryName}
          </p> // if no integration avalible, return this paragraph
        )}
      </div>
    </div>
  )
}

export default CustomerStoriesBlock
