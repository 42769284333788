import React, { useState } from "react"
import styled from "styled-components"
import Vimeo from "@u-wave/react-vimeo"

import { assets, colors, MOBILE_BREAKPOINT } from "@util/constants"
import Image from "./image"
import { Maybe, SanityImage } from "@graphql-types"
import { Container } from "@util/standard"
import ModalBox from "./modalBox"

const ThumbnailWrapper = styled(Container)<{ height?: string }>`
  position: relative;
  height: 100%;
  ${({ height }) => height && `height: ${height};`}

  width: 100%;
  max-width: 1200px;
  max-height: 600px;
  overflow: hidden;
  background: ${colors.grey};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 400px;
  }
`

const VideoWrapper = styled.div<{ hideInMobile: boolean | undefined }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ hideInMobile }) => hideInMobile && `display: none;`}
    height: 100%;
    width: 100%;
  }
`

const StyledVimeo = styled(Vimeo)<{
  foregroundOpen?: boolean
  minHeight?: string
  minWidth?: string
}>`
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}
  }
`

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 76px;
  height: 76px;
  &:hover {
    width: 85px;
    height: 85px;
  }
  cursor: pointer;

  transition: all 0.2s;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 54px;
    height: 54px;
    &:hover {
      width: 60px;
      height: 60px;
    }
  }
`

interface Props {
  url: Maybe<string> | undefined
  volume?: boolean
  height?: string
  width?: string
  minWidth?: string
  minHeight?: string
  foregroundOpen?: boolean
  autoPlayOnLoad?: boolean
  isVisible?: boolean
  background?: boolean
  controls?: boolean
  hideInMobile?: boolean
  thumbnail: Maybe<SanityImage> | undefined
  imageWidth?: string
  thumbnailHeight?: string
}

function ModalVideo(props: Props) {
  if (props == null || props.url == null) return null
  const {
    url,
    volume,
    height,
    width,
    minHeight,
    minWidth,
    hideInMobile,
    thumbnail,
    imageWidth,
    thumbnailHeight,
  } = props

  const [modalOpen, setModalOpen] = useState(false)

  const appendedUrl = `https://player.vimeo.com/video/${url}"`

  return (
    <>
      <ThumbnailWrapper height={thumbnailHeight && thumbnailHeight}>
        {thumbnail && (
          <Image
            height="100%"
            width={imageWidth && imageWidth}
            data={thumbnail}
            objectFit="cover"
            isBackground
          />
        )}
        <PlayButton
          src={assets.playButton}
          onClick={() => setModalOpen(true)}
        />
      </ThumbnailWrapper>
      <ModalBox visible={modalOpen} setVisible={setModalOpen}>
        <VideoWrapper hideInMobile={hideInMobile}>
          <StyledVimeo
            loop
            video={appendedUrl}
            autoplay={true}
            muted={!volume}
            height={height}
            width={width}
            minHeight={minHeight}
            minWidth={minWidth}
            volume={volume ? 1 : 0}
            controls={true}
            foregroundOpen={true}
            showByline={false}
          />
        </VideoWrapper>
      </ModalBox>
    </>
  )
}

export default ModalVideo
