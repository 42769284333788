import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroHeader from "../../heroHeader"

const Header = () => {
  const {
    sanityCustomerPage: { heroHeader },
  } = useStaticQuery(graphql`
    query customerPageHeroHeaderQuery {
      sanityCustomerPage {
        heroHeader {
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          waveColor
        }
      }
    }
  `)

  return <HeroHeader data={heroHeader} />
}

export default Header
